<template>
    <div>
        <div class="page-wrap" v-show="showDel">
            <div class="l-part">
                <div class="top_wrap">
                    <div class="top-nav" @click="contNex('staysigned_count')">
                        <div class="top-label">待签合同</div>
                        <div class="top-val">{{ mainInfo.staysigned_count }}</div>
                    </div>
                    <div class="top-nav" @click="contNex('signed_count')">
                        <div class="top-label">已签合同</div>
                        <div class="top-val">{{ mainInfo.signed_count }}</div>
                    </div>
                    <div class="top-nav" @click="contNex('expired_count')">
                        <div class="top-label">即将到期合同</div>
                        <div class="top-val">{{ mainInfo.expired_count }}</div>
                    </div>
                    <div class="top-nav" @click="contNex('monthSigned_count')">
                        <div class="top-label">本月已签</div>
                        <div class="top-val">{{ mainInfo.monthSigned_count }}</div>
                    </div>
                    <div class="top-nav" @click="contNex('theweek_count')">
                        <div class="top-label">本周已签</div>
                        <div class="top-val">{{ mainInfo.theweek_count }}</div>
                    </div>
                </div>
                <div class="main-chart">
                    <div id="echarts" :style="{ width: '85%', height: '550px' }"></div>
                </div>
            </div>
            <div class="r-part">
                <div class="top-info">
                    <div class="info-title">账户信息<span @click="untying(userInfo.id)"
                            style=" cursor: pointer;color: #929394;font-size: 14px;font-weight: unset">（{{ userInfo.phone }}）</span>
                    </div>
                    <div class="b-num">
                        <div class="nav-i">
                            <div class="item-w"><span>{{ mainInfo.account_info.message_num || 0 }}</span>次</div>
                            <div class="item-s">短信总数</div>
                        </div>
                        <div class="nav-i">
                            <div class="item-w"><span>{{ mainInfo.account_info.not_contracts_num || 0 }}</span>次</div>
                            <div class="item-s">普通合同</div>
                        </div>
                        <div class="nav-i" v-if="$store.state.identity == 2">
                            <div class="item-w"><span>{{ mainInfo.account_info.contracts_num || 0 }}</span>次</div>
                            <div class="item-s">认证合同</div>
                        </div>
                    </div>
                </div>
                <div class="top-info" v-if="userInfo.role !== 3" style="margin-top: 20px;height: 240px">
                    <div class="info-title">用户信息</div>
                    <div class="i-know">
                        <div class="l-echarts">
                            <div id="circle" :style="{ width: '300px', height: '200px', left: '-25px' }"></div>
                        </div>
                        <div class="r-msg">
                            <div class="nav-wrap">
                                <div class="warp-label flex-c">普通用户</div>
                                <div class="warp-value">{{ mainInfo.account_info.ordinary_num }}人</div>
                            </div>
                            <div class="nav-wrap">
                                <div class="warp-label warp-two flex-c">副管理员</div>
                                <div class="warp-value">{{ mainInfo.account_info.deputy_num }}人</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="top-info" style="margin-top: 20px;height: 330px;position: relative">
                    <div class="info-title">待签署合同</div>
                    <div class="s-title">
                        <div class="num">{{ storePrams.total }}<span>份</span></div>
                        <div class="word">待办总量</div>
                    </div>
                    <div class="contract-list">
                        <div class="list-item flex-b" v-for="(item, index) in signList" :key="index">
                            <div style="align-items: center;display: flex">
                                <div class="l-tip flex-c">待签署</div>
                                <div class="con-mame">{{ item.contract_name }}</div>
                            </div>
                            <div class="con-icon" @click="seeDetail(item.id)"><i class="iconfont icon-chakan2"></i>
                            </div>
                        </div>
                    </div>
                    <div class="see-more" @click="goPage">查看更多</div>
                </div>
            </div>
        </div>
        <div>
            <detail ref="contractDel" @goBack="showSelf"></detail>
        </div>
        <Modal v-model="modal" title="解绑用户" @on-ok="ok" @on-cancel="cancel">
            <p>确定解绑嘛！！！</p>
        </Modal>
    </div>
</template>

<script>
import { contractDetails, statistics, staySign, relieve, } from '../../api/interFace'
import detail from '../management/components/contractDel'
import * as echarts from 'echarts';
// import {setCookies,removeCookies} from '@/utils/helper'

import config from '@/config/index'
export default {
    name: "index",
    components: { detail },
    data() {
        return {
            modal: false,
            
            isIndependentDeployment: config.isIndependentDeployment,//是否为独立部署
            untyingId: "",
            topPart: [
                {
                    label: '',
                    val: ''
                }
            ],
            mainInfo: {
                account_info: {}
            },
            storePrams: {
                limit: 4,
                page: 1,
                total: 0
            },
            signList: [],
            showDel: true
        }
    },
    created() {
        
        this.$store.commit('changePollingSpin', true)
        this.getInfo()
        this.getList()
    },
    mounted() {
        this.initChat()
        if (this.userInfo.role !== 3) {
            this.initCircle()
        }
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo
        },
    },
    methods: {
        getExpiresTime(expiresTime) {
            let nowTimeNum = Math.round(new Date() / 1000)
            let expiresTimeNum = expiresTime - nowTimeNum
            return parseFloat(parseFloat(parseFloat(expiresTimeNum / 60) / 60) / 24)
        },
        contNex(e) {
            this.$router.push({ path: '/management/contract', query: { name: e } })
        },
        untying(e) {
            this.$Modal.confirm({
                title: '解绑提示',
                content: '请问您是否确认解绑该用户！！！',
                onOk: () => {
                    relieve({ id: e }).then(res => {
                        this.$Message.success(res.msg)
                        this.getlist()
                    }).catch(res => {
                        this.$Message.error(res.msg)
                    })
                },
                onCancel: () => {
                }
            });
        },
        ok() {
            this.untyingId
            this.$Message.info('Clicked ok');
        },
        cancel() {
            // this.$Message.info('Clicked cancel');
        },
        getInfo() {
            statistics({ edition_type: 1 }).then(res => {
                this.mainInfo = res.data || {}
                this.mainInfo.account_info = res.data.account_info || {}
                let s = res.data.contract_statistics.notsigned_num
                let z = res.data.contract_statistics.signed_num
                let dateList = []
                let valList = []
                let secondVal = []
                if(this.isIndependentDeployment){
                    for (let i in s) {
                        dateList.push(i)
                        valList.push(s[i])
                    }               
                    for (let j in z) {
                        secondVal.push(z[j])
                    }
                }else {
                    s.forEach((item,index) => {                    
                        dateList.push(item.date)
                        valList.push(item.value)
                    });
                    z.forEach(item => {
                        secondVal.push(item.value)
                    });
                }               
                console.log('secondVal',secondVal)
                this.initChat(dateList, valList, secondVal)
                this.initCircle(res.data.account_info.deputy_num, res.data.account_info.ordinary_num)
            }).catch(res => {
                console.log(res)
            })
        },
        initChat(a, c, d) {
            var chartDom = document.getElementById('echarts');
            var myChart = echarts.init(chartDom);
            var option;
            option = {
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '3%',
                    containLabel: true
                },
                xAxis: {
                    type: 'category',
                    data: a
                },
                yAxis: {
                    type: 'value'
                },
                series: [
                    {
                        name: '未签合同',
                        type: 'line',
                        stack: 'no',
                        itemStyle: {
                            normal: {
                                color: '#0089E2',
                                lineStyle: {
                                    color: '#0089E2'
                                }
                            }
                        },
                        data: c
                    },
                    {
                        name: '已签合同',
                        type: 'line',
                        stack: 'yes',
                        itemStyle: {
                            normal: {
                                color: '#F77F54',
                                lineStyle: {
                                    color: '#F77F54'
                                }
                            }
                        },
                        data: d
                    },
                ]
            };
            myChart.setOption(option);
        }, 
        initCircle(a, b) {
            var chartDom = document.getElementById('circle');
            var myChart = echarts.init(chartDom);
            var option;
            option = {
                tooltip: {
                    trigger: 'item'
                },
                series: [
                    {
                        name: '用户信息',
                        type: 'pie',
                        radius: ['40%', '75%'],
                        avoidLabelOverlap: false,
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: '22',
                                fontWeight: 'bold'
                            }
                        },
                        labelLine: {
                            show: false
                        },
                        data: [
                            {
                                value: a,
                                name: '副管理员',
                                itemStyle: {
                                    normal: {
                                        color: '#F77F54',
                                    }
                                },
                            },
                            {
                                value: b,
                                name: '普通会员',
                                itemStyle: {
                                    normal: {
                                        color: '#4877E8',
                                    }
                                },
                            }
                        ]
                    }
                ]
            }
            myChart.setOption(option);
        },
        getList() {
            staySign(this.storePrams).then(res => {
                this.signList = res.data.data || []
                this.storePrams.total = res.data.total
            })
        },
        seeDetail(row) {
            const msg = this.$Message.loading({
                content: 'Loading...',
                duration: 0
            });
            contractDetails({ id: row, pcStatus: 1 }).then(res => {
                this.showDel = false
                this.$nextTick(() => {
                    this.$refs.contractDel.showDetail(res)
                })
                setTimeout(msg, 100);
            }).catch(res => {
                this.$Message.error(res.msg)
            })
        },
        showSelf() {
            this.showDel = true
        },
        goPage() {
            this.$router.push({ path: '/management/contract' })
        }
    }
}
</script>

<style scoped lang="scss">
.page-wrap {
    display: flex;
    justify-content: space-between;
    height: 60vh;

    .l-part {
        width: 69%;

        .top_wrap {
            width: 100%;
            height: 124px;
            background: #FFFFFF;
            border-radius: 8px;
            display: flex;

            .top-nav {
                flex: 1;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;
                &:not(:last-child) {
                    &:after {
                        position: absolute;
                        content: '';
                        display: inline-block;
                        width: 2px;
                        height: 56px;
                        background: #E4EAF9;
                        border-radius: 1px;
                        right: 0;
                    }
                }
                .top-label {
                    font-size: 18px;
                    font-weight: bold;
                    color: #191A1F;
                }
                .top-val {
                    font-size: 24px;
                    font-weight: bold;
                    color: #4877E8;
                }
            }
        }
        .main-chart {
            width: 100%;
            height: 650px;
            background: #FFFFFF;
            border-radius: 8px;
            margin-top: 20px;
            padding-left: 30px;
            padding-top: 40px;
            box-sizing: border-box;
        }
    }

    .r-part {
        width: 30%;
        .top-info {
            width: 100%;
            height: 182px;
            background: #FFFFFF;
            border-radius: 8px;
            padding: 24px 34px;
            box-sizing: border-box;
            .info-title {
                font-size: 16px;
                font-weight: bold;
                color: #191A1F;
            }
            .see-more {
                position: absolute;
                right: 20px;
                bottom: 2px;
                font-size: 16px;
                color: #1a9aef;
                cursor: pointer;
                &:hover {
                    text-decoration: underline;
                }
            }
            .b-num {
                display: flex;
                margin-top: 50px;
                justify-content: space-around;
                .nav-i {
                    font-size: 14px;
                    color: #929394;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    span {
                        font-size: 18px;
                        font-weight: bold;
                        color: #4877E8;
                    }
                }
            }
            .i-know {
                display: flex;
                align-items: center;
                position: relative;
            }

            .r-msg {
                position: absolute;
                left: 240px;
                top: 40px;
            }

            .nav-wrap {
                display: flex;
                align-items: center;
                margin-left: 12px;
                margin-top: 16px;
                .warp-label {
                    font-size: 17px;
                    color: #191A1F;
                    &:before {
                        display: inline-block;
                        content: '';
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        background: #1a9aef;
                        margin-right: 5px;
                    }
                }
                .warp-two {
                    &:before {
                        background: #F77F54;
                    }
                }
                .warp-value {
                    font-size: 16px;
                    color: #191A1F;
                    opacity: 0.6;
                    margin-left: 20px;
                }
            }
        }

        .s-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .num {
                font-size: 24px;
                font-weight: bold;
                color: #4877E8;

                span {
                    font-weight: unset;
                    height: 16px;
                    font-size: 16px;
                    color: #545F78;
                    margin-left: 3px;
                }
            }
            .word {
                font-size: 16px;
                font-weight: bold;
                color: #545F78;
            }
        }

        .contract-list { 
            .list-item {
                margin-top: 12px;
                .l-tip {
                    width: 58px;
                    height: 26px;
                    background: #EDF0F9;
                    border: 1px solid #E3E7F4;
                    border-radius: 4px;
                    font-size: 11px;
                    color: #545F78;
                    margin-right: 12px;
                }
                .con-icon {
                    .iconfont {
                        font-size: 24px;
                        cursor: pointer;

                        &:hover {
                            color: #1a9aef;
                        }
                    }
                }
            }
        }
    }
}
</style>
